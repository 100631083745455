import { z } from 'zod'
import {
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
} from './common.schema'

export const SelectItemSchema = z.object({
  event: z.literal('select_item'),

  event_data: eventDataSchema,
  ecommerce: ecommerceSchema.extend({
    items: z.array(
      ecommerceItemSchema.superRefine((val, ctx) => {
        if (
          typeof val.item_id === 'undefined' &&
          typeof val.item_name === 'undefined'
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'Provide either `item_id` or `item_name` on ecommerce item for this event',
          })
        }

        if (typeof val.price !== 'number') {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            expected: 'number',
            received: typeof val.price,
            message: 'Price is required on ecommerce item for this event',
          })
        }

        if (typeof val.quantity !== 'number') {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            expected: 'number',
            received: typeof val.quantity,
            message: 'Quantity is required on ecommerce item for this event',
          })
        }
      }),
    ),
  }),
})

export type SelectItemEvent = z.infer<typeof SelectItemSchema>
