import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemSearch } from '@moonpig/launchpad-assets'
import { sharedButtonStyles } from './sharedButtonStyles'
import { useLocaleText } from '../locale'
import { ICON_SIZE_PX } from './constants'

const StyledSubmitButton = styled.button`
  ${sharedButtonStyles}
  ${s({
    right: 0,
    color: 'colorBlack00',
    bgcolor: 'colorPrimary02',
  })}
  z-index: 2;

  &[disabled] {
    background-color: transparent;
  }
`

type SearchSubmitButtonProps = {
  disabled: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const SubmitButton: FC<
  React.PropsWithChildren<SearchSubmitButtonProps>
> = ({ disabled, onClick }) => {
  const t = useLocaleText()

  return (
    <StyledSubmitButton
      disabled={disabled}
      onClick={onClick}
      aria-label={t('search.submit_search')}
      type="button"
    >
      <IconSystemSearch width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
    </StyledSubmitButton>
  )
}
