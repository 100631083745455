import { z } from 'zod'
import { ecommerceSchema, eventDataSchema } from './common.schema'

export const AddPaymentInfoSchema = z.object({
  event: z.literal('add_payment_info'),

  ecommerce: ecommerceSchema,
  event_data: eventDataSchema,
})

export type AddPaymentInfoEvent = z.infer<typeof AddPaymentInfoSchema>
