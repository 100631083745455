import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

const StyledHighlightedSelection = styled.span`
  ${s({ typography: 'typeBodyLabel' })}
`
const StyledRegularSelection = styled.span`
  ${s({ typography: 'typeBodyText' })}
`

export const HighlightText = ({
  text,
  value,
}: {
  text: string
  value: string
}) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(value)})`, 'gi'))
  return (
    <>
      {parts.map((part, i) => {
        if (part.toLowerCase() !== value.toLowerCase()) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <StyledHighlightedSelection key={i}>
              {part}
            </StyledHighlightedSelection>
          )
        }
        if (part.length) {
          // eslint-disable-next-line react/no-array-index-key
          return <StyledRegularSelection key={i}>{part}</StyledRegularSelection>
        }
        /* istanbul ignore next */
        return null
      })}
    </>
  )
}
