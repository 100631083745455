import React, { FC, FormEvent, useState } from 'react'
import {
  Alert,
  Box,
  Heading,
  Modal,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { TextInput, PasswordInput } from '@moonpig/launchpad-forms'
import { useAuthFetch } from '@moonpig/web-core-auth'
import {
  oneTapAuthenticationService,
  OidcTokenLoginAccountLinkData,
} from './GoogleOneTapService'
import { useOneTapLocaleText } from './GoogleOneTap.locale'
import GoogleLogo from './assets/GoogleLogo.svg'
import {
  trackOneTapAccountLinkFailure,
  trackOneTapAccountLinkSuccess,
} from './tracking'

const StyledImg = styled('img')`
  ${s({
    marginRight: 5,
    display: {
      xs: 'none',
      md: 'inline-block',
    },
  })}
`

type GoogleOneTapLinkModalProps = {
  isOpen: boolean
  onClose: () => void
  data: OidcTokenLoginAccountLinkData
}

export const GoogleOneTapLinkModal: FC<GoogleOneTapLinkModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const authFetch = useAuthFetch()
  const localiseText = useOneTapLocaleText()
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState('')
  const [pageError, setPageError] = useState('')

  const { linkAccount } = oneTapAuthenticationService(authFetch)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      setIsLoading(true)

      const accountLinkResult = await linkAccount({
        email: data.email,
        password,
        flowId: data.flowId,
      })

      switch (accountLinkResult.status) {
        case 'SUCCESS':
          trackOneTapAccountLinkSuccess()
          return onClose()
        case 'BAD_REQUEST':
          trackOneTapAccountLinkFailure({
            errorCode: accountLinkResult.code,
            errorMessage: accountLinkResult.errorMessage,
          })
          setPasswordErrors(accountLinkResult.errorMessage)
          break
        case 'ERROR':
        case 'ACCOUNT_LOCKED':
          trackOneTapAccountLinkFailure({
            errorCode: accountLinkResult.code,
            errorMessage: accountLinkResult.errorMessage,
          })
          setPageError(
            accountLinkResult.errorMessage ??
              localiseText('account_linking_error'),
          )
          break
        default:
          setPageError(localiseText('account_linking_error'))
      }
    } catch (error) {
      trackOneTapAccountLinkFailure({
        errorMessage: 'Exception occurred',
      })
      setPageError(localiseText('account_linking_error'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      testId={'google-one-tap-link-modal'}
      label="Google One Tap Account Link"
      isOpen={isOpen}
      onDismiss={() => onClose()}
      hasCloseButton
      mobilePagePeek
    >
      <Box width={{ xs: '100vw', md: 1 }}>
        <Heading
          level="h2"
          as="h1"
          mb={4}
          textAlign={{ xs: 'left' }}
          display="flex"
          alignItems="center"
          data-testid="formStepTitle"
        >
          <StyledImg src={GoogleLogo} alt="google logo" />
          {localiseText('account_linking_title')}
        </Heading>
        <Text mb={6}>
          {localiseText('account_linking_description', 'Google')}
        </Text>
        <form
          method="post"
          onSubmit={handleSubmit}
          noValidate
          style={{ marginTop: 20 }}
        >
          <TextInput
            name="email"
            value={data.email}
            disabled
            onChange={() => {}}
            label={localiseText('account_linking_email_label')}
            mb={6}
          />
          <PasswordInput
            name="password"
            label={localiseText('account_linking_password_label')}
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="password"
            error={!!passwordErrors}
            helperText={passwordErrors}
            mb={8}
          />
          {pageError && (
            <Box>
              <Alert variant="error" my={5}>
                {pageError}
              </Alert>
            </Box>
          )}
          <PrimaryButton type="submit" disabled={isLoading} width="100%" mb={6}>
            {localiseText('account_linking_button')}
          </PrimaryButton>
        </form>
      </Box>
    </Modal>
  )
}
