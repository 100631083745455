import { z } from 'zod'
import {
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
} from './common.schema'

export const ViewItemListSchema = z.object({
  event: z.literal('view_item_list'),

  ecommerce: ecommerceSchema.extend({
    items: z.array(
      ecommerceItemSchema.superRefine((val, ctx) => {
        if (typeof val.item_list_name !== 'string') {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            expected: 'string',
            received: typeof val.item_list_name,
          })
        }

        if (typeof val.index !== 'number') {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            expected: 'number',
            received: typeof val.index,
          })
        } else if (val.index <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '`index` should be greater than 0',
          })
        }
      }),
    ),
  }),
  event_data: eventDataSchema,
})

export type ViewItemListEvent = z.infer<typeof ViewItemListSchema>
