import { useLoggedIn } from '@moonpig/web-core-auth'
import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { useEffect } from 'react'
import { useRouter } from '@moonpig/web-core-routing'
import { useExperiments } from '@moonpig/web-core-experiments'
import { Region } from '@moonpig/web-core-types'
import { EventObject, trackGAEvent } from '@moonpig/web-core-analytics'
import { GetCustomerCreditQuery } from './__generated__/useCreditAmountConfig'

const GetCustomerCreditGQL = gql`
  query GetCustomerCredit {
    me {
      customer {
        id
        credits {
          availableBalance {
            display
            amount
            centAmount
          }
        }
      }
    }
  }
`

export const useCreditAmountConfig = ({ region }: { region: Region }) => {
  const { loggedIn } = useLoggedIn()
  const creditNavExperiment =
    useExperiments('customer-credit-nav') === 'variant'
  const router = useRouter()

  const [fetchCredit, { data }] =
    useLazyQuery<GetCustomerCreditQuery>(GetCustomerCreditGQL)

  const displayAmount =
    data?.me.customer?.credits?.availableBalance?.display || ''
  const hasAvailableBalance =
    data?.me.customer?.credits?.availableBalance &&
    data?.me.customer?.credits?.availableBalance?.centAmount > 0

  const gaEventTracking = (
    contentType: string,
    action: string,
    label: string,
  ): EventObject => {
    return {
      event: 'select_content',

      content_data: {
        content_type: contentType,
      },

      // For GA3 backward compatibility
      event_data: {
        action,
        category: 'header',
        label,
        non_interaction: true,
        value: undefined,
      },
    }
  }

  useEffect(() => {
    if (loggedIn && process.env.ENABLE_CREDIT_NAV === 'true') {
      fetchCredit()
    }
  }, [fetchCredit, loggedIn])

  useEffect(() => {
    const amount = data?.me?.customer?.credits?.availableBalance?.amount || '0'

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLoadedEventIndex = window.dataLayer?.findIndex((event: any) => {
      return event.page && event.event === 'dataLoaded'
    })

    if (dataLoadedEventIndex > -1) {
      window.dataLayer[dataLoadedEventIndex].page.credit = +amount
    }
  }, [data])

  if (hasAvailableBalance && creditNavExperiment) {
    return {
      creditDisplayAmount: displayAmount,
      onModalOpen: () => {
        trackGAEvent(
          gaEventTracking(
            'header | credit open modal | view credit',
            'open credit modal',
            'credit modal',
          ),
        )
      },
      onModalDismiss: () => {},
      onShopNowLinkClick: () => {
        router.push({
          name: 'content',
          params: {
            region,
            parts: ['personalised-cards', 'all'],
          },
        })
        trackGAEvent(
          gaEventTracking(
            'header | credit view modal | click shop now btn',
            'click shop now button',
            'shop now',
          ),
        )
      },
    }
  }
  return undefined
}
