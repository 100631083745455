import React, { createContext, FC, useContext } from 'react'
import { useTrackExperiment } from './useTrackExperiment'

export type Experiments = {
  [key: string]: string
}

export const experimentsContext = createContext<Experiments>({})

export const ExperimentsProvider: FC<
  React.PropsWithChildren<{ experiments: Experiments }>
> = ({ children, experiments }) => (
  <experimentsContext.Provider value={experiments}>
    {children}
  </experimentsContext.Provider>
)

export const useExperiments = (key: string): string | undefined => {
  const experiments = useContext(experimentsContext)
  const variant = experiments[key]

  useTrackExperiment({ key, variant })

  return variant || undefined
}

export const useExperiment: <T extends string | undefined>(
  key: string,
  fallback?: T,
) => string | (T extends string ? T : undefined) = (key, fallback) => {
  const experiments = useContext(experimentsContext)
  const variant = experiments[key]

  useTrackExperiment({ key, variant })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (variant || fallback) as any
}

export const useExperimentList = <T extends string>(
  experimentList: T[],
): { [key in T]: string } => {
  const experiments = useContext(experimentsContext)

  const selectedExperiments = experimentList.reduce((acc, current) => {
    if (experiments[current]) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTrackExperiment({ key: current, variant: experiments[current] })
      return { ...acc, [current]: experiments[current] }
    }
    return acc
  }, {} as { [key in T]: string })

  return selectedExperiments
}

export const useAllExperiments = (): Experiments => {
  return useContext(experimentsContext)
}
