import { z } from 'zod'
import {
  discountDataSchema,
  errorDataSchema,
  eventDataSchema,
} from './common.schema'

export const DiscountFailureSchema = z.object({
  event: z.literal('discount_failure'),

  discount_data: discountDataSchema,
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type DiscountFailureEvent = z.infer<typeof DiscountFailureSchema>
