import { z } from 'zod'
import {
  userDataSchema,
  zodAddressCountString,
  eventDataSchema,
} from './common.schema'

export const DeleteRecipientSchema = z.object({
  event: z.literal('delete_recipient'),

  user_data: userDataSchema.extend({
    address_count: zodAddressCountString,
  }),

  event_data: eventDataSchema,
})

export type DeleteRecipientEvent = z.infer<typeof DeleteRecipientSchema>
