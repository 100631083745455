import { z } from 'zod'
import {
  basketDataSchema,
  discountDataSchema,
  ecommerceSchema,
  eventDataSchema,
} from './common.schema'

export const RemoveFromCartSchema = z.object({
  event: z.literal('remove_from_cart'),

  basket_data: basketDataSchema,
  ecommerce: ecommerceSchema,
  event_data: eventDataSchema,
  discount_data: discountDataSchema,
})

export type RemoveFromCartEvent = z.infer<typeof RemoveFromCartSchema>
