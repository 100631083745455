import React, { FC, useEffect } from 'react'
import {
  Box,
  Flex,
  Heading,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { InspirationType } from './Inspirations/Inspiration'
import { Inspirations } from './Inspirations/Inspirations'
import { Reminder } from './Reminders/Reminder'
import { Reminders } from './Reminders/Reminders'
import type {
  InspirationItemType,
  RecentSearchItemType,
  RecentSearchType,
  ReminderItemType,
} from './types'
import { RecentSearches } from './RecentSearches/RecentSearches'
import { useLocaleText } from '../locale'

const StyledContainer = styled.div.attrs(({ isOpen }: { isOpen: boolean }) => ({
  className: !isOpen && 'hidden',
}))<{ isOpen: boolean }>`
  position: relative;
  top: ${spacingPx(12)};
  z-index: 3;
  ${s({
    pb: 4,
    pt: 6,
  })};
  &.hidden {
    display: none;
  }
`
const StyledHeaderFlex = styled(Flex)`
  ${s({ px: 5 })}
  ${breakpointDown('md')} {
    width: 100%;
  }
  width: 320px;
`

const StyledClearAllButton = styled(TertiaryButton)`
  ${s({ color: 'colorInteractionIcon' })}
`

type RecentSearchesWithHeaderProps = {
  recentSearches: RecentSearchType[]
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
  onClearRecentSearches: () => void
}

const RecentSearchesWithHeader: FC<RecentSearchesWithHeaderProps> = ({
  recentSearches,
  onClearRecentSearches,
  ...recentSearchProps
}) => {
  const t = useLocaleText()

  return (
    <div data-testid="lp-nav-focusable-recent-search">
      {
        <StyledHeaderFlex
          flexDirection="row"
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Heading
            level="h4"
            marginBottom={0}
            color="colorTextHeader"
            typography={{
              xs: 'typeMobileDisplay05',
              md: 'typeDesktopDisplay06',
            }}
          >
            {t('search.recent_searches')}
          </Heading>
          {recentSearches.length > 1 && (
            <StyledClearAllButton
              tabIndex={0}
              color={'colorInteractionIcon'}
              onClick={onClearRecentSearches}
              data-testid="lp-nav-search-recent-clear"
              padding={0}
              margin={0}
              aria-label={t('search.clear_all_recent_searches_label')}
            >
              {t('search.clear_all_recent_searches')}
            </StyledClearAllButton>
          )}
        </StyledHeaderFlex>
      }
      <RecentSearches recentSearches={recentSearches} {...recentSearchProps} />
    </div>
  )
}

type FocusableSearchBoxProps = {
  isOpen: boolean
  inspirations: InspirationType[]
  reminders?: Reminder[]
  recentSearches: RecentSearchType[]
  inputValue: string | null
  inspirationsHeaderText?: string
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType | InspirationItemType | ReminderItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemindersVisible?: () => void
  onRemoveRecentSearch: (item: RecentSearchType) => void
  onClearRecentSearches: () => void
  onSelectItem: (selectedValue: string) => void
}

export const FocusableSearchBox: FC<FocusableSearchBoxProps> = ({
  isOpen,
  inspirations,
  reminders = [],
  recentSearches,
  inputValue,
  getItemProps,
  getMenuProps,
  onRemindersVisible = () => {},
  onRemoveRecentSearch,
  onClearRecentSearches,
  onSelectItem,
}) => {
  const t = useLocaleText()
  const inspirationsVisible =
    isOpen && inspirations.length && (inputValue === null || inputValue === '')

  const recentSearchesVisible =
    isOpen &&
    recentSearches.length &&
    (inputValue === null || inputValue === '')

  const remindersVisible =
    isOpen && reminders.length && (inputValue === null || inputValue === '')

  const inspirationsHeaderVisible = inspirationsVisible || remindersVisible

  useEffect(() => {
    if (remindersVisible) {
      onRemindersVisible()
    }
  }, [onRemindersVisible, remindersVisible])

  return (
    <StyledContainer
      isOpen={inspirationsVisible || recentSearchesVisible || remindersVisible}
      onKeyDown={(e: React.KeyboardEvent<HTMLUListElement>) => {
        const { target } = e

        const targetElement = target as HTMLUListElement
        const listItemText = targetElement.textContent
        const isListElement = targetElement.tagName === 'LI'

        if (e.key === 'Enter' && isListElement && listItemText) {
          e.nativeEvent.preventDefault()
          onSelectItem(listItemText)
        }
      }}
    >
      {recentSearchesVisible ? (
        <RecentSearchesWithHeader
          recentSearches={recentSearches}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          onClearRecentSearches={onClearRecentSearches}
          onRemoveItem={onRemoveRecentSearch}
        />
      ) : null}
      {inspirationsHeaderVisible ? (
        <Box paddingLeft={5} paddingTop={4} paddingBottom={3}>
          <Heading
            level="h4"
            color="colorTextHeadline"
            marginBottom={0}
            typography={{
              xs: 'typeMobileDisplay05',
              md: 'typeDesktopDisplay06',
            }}
          >
            {t('search.suggested')}
          </Heading>
        </Box>
      ) : null}
      {remindersVisible ? (
        <div data-testid="lp-nav-focusable-searchbox-reminders">
          <Reminders
            reminders={reminders}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
          />
        </div>
      ) : null}
      {inspirationsVisible ? (
        <div data-testid="lp-nav-focusable-searchbox-inspirations">
          <Inspirations
            inspirations={inspirations}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
            startIndex={recentSearches.length}
          />
        </div>
      ) : null}
    </StyledContainer>
  )
}
