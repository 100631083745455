import React, { FC } from 'react'
import {
  IconLogoMaestro,
  IconLogoVisa,
  IconLogoMastercard,
  IconLogoApplePay,
  IconLogoPaypal,
  IconLogoIdeal,
  IconLogoKlarna,
  IconLogoAmericanExpress,
} from '@moonpig/launchpad-assets'
import { FooterNavSection } from './FooterNavSection'
import { useLocaleText } from '../locale'

const logoMap = {
  Visa: { Component: IconLogoVisa, width: '32px', height: '21px' },
  Mastercard: { Component: IconLogoMastercard, width: '40px', height: '33px' },
  Maestro: { Component: IconLogoMaestro, width: '40px', height: '33px' },
  'Apple Pay': { Component: IconLogoApplePay, width: '40px', height: '33px' },
  PayPal: { Component: IconLogoPaypal, width: '55px', height: '40px' },
  Ideal: { Component: IconLogoIdeal, width: '40px', height: '33px' },
  Klarna: { Component: IconLogoKlarna, width: '40px', height: '33px' },
  'American Express': {
    Component: IconLogoAmericanExpress,
    width: '40px',
    height: '33px',
  },
}

export type PaymentProviders = keyof typeof logoMap

type PaymentMethodProps = {
  providers?: PaymentProviders[]
}

export const PaymentMethods: FC<
  React.PropsWithChildren<PaymentMethodProps>
> = ({
  providers = ['Visa', 'Mastercard', 'Apple Pay', 'Maestro', 'PayPal'],
}) => {
  const t = useLocaleText()
  return (
    <div data-testid="lp-nav-footernav-paymentmethods">
      <FooterNavSection
        title={t(`footer.nav.payment_methods`)}
        colGap={{ xs: 3, md: 4 }}
      >
        {providers.map(provider => {
          const { width, height, Component } = logoMap[provider]
          return (
            <li key={provider}>
              <Component
                role="img"
                aria-label={provider}
                width={width}
                height={height}
              />
            </li>
          )
        })}
      </FooterNavSection>
    </div>
  )
}
