import { z } from 'zod'
import { discountDataSchema, eventDataSchema } from './common.schema'

export const AddDiscountSchema = z.object({
  event: z.literal('add_discount'),

  discount_data: discountDataSchema,
  event_data: eventDataSchema,
})

export type AddDiscountEvent = z.infer<typeof AddDiscountSchema>
