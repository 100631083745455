import { z } from 'zod'
import {
  contentDataSchema,
  discountDataSchema,
  ecommerceSchema,
  errorDataSchema,
  eventDataSchema,
} from './common.schema'

export const SelectContentSchema = z.object({
  event: z.literal('select_content'),

  event_data: eventDataSchema,

  content_data: contentDataSchema.optional(),
  discount_data: discountDataSchema.optional(),
  ecommerce: ecommerceSchema.optional(),
  error_data: errorDataSchema.optional(),
})

export type SelectContentEvent = z.infer<typeof SelectContentSchema>
