import React from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { breakpointDown, breakpointUp, styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'

const getStyledInputClassNames = ({
  value,
  isSearchOpen,
}: {
  value?: string
  isSearchOpen?: boolean
}) => ({
  className: `${value ? 'is-active' : ''} ${isSearchOpen ? 'is-open' : ''}`,
})

const StyledInput = styled.input.attrs(getStyledInputClassNames)<{
  isSearchOpen?: boolean
}>`
  ${s({
    py: 4,
    typography: 'typeBodyLabel',
    bgcolor: 'colorBackground03',
    color: 'colorBlack60',
    pl: 6,
    m: 0,
    borderColor: 'colorPrimary02',
    border: 2,
    borderRadius: 6,
  })}
  display: block;
  width: 100%;
  min-height: ${spacingPx(12)};
  position: absolute;
  z-index: 2;

  &::-ms-clear {
    display: none;
  }

  /* stylelint-disable property-no-vendor-prefix */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &::placeholder {
    ${s({ color: 'colorTextBody', typography: 'typeBodyLabel' })}
  }

  &:focus {
    ${s({
      borderColor: 'colorPrimary02',
      border: 3,
      color: 'colorTextLabel',
      fontWeight: 'normal',
    })}
    outline: transparent;
    &::placeholder {
      ${s({ fontWeight: 'normal' })}
    }
  }

  &.is-active {
    ${s({
      pr: 15,
      bgcolor: 'colorBackground03',
      color: 'colorTextLabel',
      fontWeight: 'normal',
      border: 3,
      borderColor: 'colorPrimary02',
    })}
  }

  &.is-open {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    ${s({
      borderColor: 'colorBorder03',
      color: 'colorTextLabel',
      fontWeight: 'normal',
      pl: 6,
      right: 0,
      bgcolor: 'colorBackground01',
    })}
    ${breakpointDown('md')} {
      width: calc(100% - 48px);
    }
    ${breakpointUp('md')} {
      border-radius: 8px 8px 0px 0px;
    }
  }

  &:focus ~ button:nth-of-type(3) {
    ${s({
      right: 0,
      color: 'colorBlack00',
      bgcolor: 'colorPrimary02',
    })}

    ${breakpointUp('md')} {
      border-radius: 0px 8px 0px 0px;
    }
  }
`

type SearchInputProps = {
  isSearchOpen?: boolean
  value?: string | number | string[]
  placeholder: string
} & React.HTMLProps<HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    const { isSearchOpen, value, placeholder, as, ...filteredInputProps } =
      props
    return (
      <StyledInput
        {...filteredInputProps}
        placeholder={placeholder}
        value={value}
        ref={ref}
        as={
          as as
            | keyof JSX.IntrinsicElements
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            | React.ComponentType<any>
        }
        isSearchOpen={isSearchOpen}
      />
    )
  },
)

Input.displayName = 'SearchInput'
