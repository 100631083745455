import { z } from 'zod'
import {
  basketDataSchema,
  ecommerceSchema,
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodLowerCaseString,
} from './common.schema'
import { refinedEcommerceItemSchema } from './refinements/purchase-ecommerce.refine'

export const PurchaseSchema = z.object({
  event: z.literal('purchase'),

  user_data: userDataSchema
    .required()
    .extend({ address_count: zodAddressCountString.optional() }),
  basket_data: basketDataSchema.optional(),
  event_data: eventDataSchema,

  ecommerce: ecommerceSchema.extend({
    transaction_id: zodLowerCaseString,
    items: z.array(refinedEcommerceItemSchema),
  }),
})

export type PurchaseEvent = z.infer<typeof PurchaseSchema>
