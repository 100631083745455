import React from 'react'
import Head from 'next/head'

import logoSquare from '../../../assets/greetz/images/logo-square.png'
import logoSquareWin8 from '../../../assets/greetz/images/logo-square-win8.png'

export const GlobalHead = () => (
  <Head>
    <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    <meta property="og:image" content={logoSquare} key="image" />
    <meta property="og:site_name" content="Greetz" />
    <meta httpEquiv="x-ua-compatible" content="IE=edge" />
    <meta name="msvalidate.01" content="E5AAE4A70F4968BE0B08670A0AFB22A4" />
    <meta name="msapplication-TileColor" content="#dae7f1" />
    <meta name="msapplication-TileImage" content={logoSquareWin8} />
    <meta name="version" content={process.env.APP_VERSION} />
  </Head>
)
