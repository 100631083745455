import { z } from 'zod'
import { AddToCartSchema } from '../schemas/addToCart.schema'
import { CustomerSchema } from '../schemas/customer.schema'
import { LoginSchema } from '../schemas/login.schema'
import { LogoutSchema } from '../schemas/logout.schema'
import { PurchaseSchema } from '../schemas/purchase.schema'
import { ScreenViewSchema } from '../schemas/screenView.schema'
import { SelectContentSchema } from '../schemas/selectContent.schema'
import { SelectItemSchema } from '../schemas/selectItem.schema'
import { SignUpSchema } from '../schemas/signUp.schema'
import { ViewCartSchema } from '../schemas/viewCart.schema'
import { ViewItemSchema } from '../schemas/viewItem.schema'
import { ViewItemListSchema } from '../schemas/viewItemList.schema'
import { OutstandingEventNames } from './eventList'
import { BeginCheckoutSchema } from '../schemas/beginCheckout.schema'
import { AddRecipientSchema } from '../schemas/addRecipient.schema'
import { DeleteRecipientSchema } from '../schemas/deleteRecipient.schema'
import { SelectRecipientSchema } from '../schemas/selectRecipient.schema'
import { UpdateRecipientSchema } from '../schemas/updateRecipient.schema'
import { RemoveFromCartSchema } from '../schemas/removeFromCart.schema'
import { ViewXsellResultsSchema } from '../schemas/viewXsellResults.schema'
import { AddDiscountSchema } from '../schemas/addDiscount.schema'
import { AddPaymentInfoSchema } from '../schemas/addPaymentInfo.schema'
import { DiscountErrorSchema } from '../schemas/discountError.schema'
import { DiscountFailureSchema } from '../schemas/discountFailure.schema'
import { RecipientErrorSchema } from '../schemas/recipientError.schema'
import { RemoveDiscountSchema } from '../schemas/removeDiscount.schema'

const CatchAllSchema = z.object({}).catchall(z.any())
export const EventObjectSchema = CatchAllSchema

const ManualValidationCatchAllSchema = z
  .object({
    event: OutstandingEventNames,
  })
  .catchall(z.any())

export const ManualEventObjectSchema = z.discriminatedUnion('event', [
  SelectContentSchema,
  PurchaseSchema,
  CustomerSchema,
  AddToCartSchema,
  ScreenViewSchema,
  ViewCartSchema,
  ViewItemListSchema,
  ViewItemSchema,
  SelectItemSchema,
  LoginSchema,
  SignUpSchema,
  LogoutSchema,
  BeginCheckoutSchema,
  AddRecipientSchema,
  DeleteRecipientSchema,
  SelectRecipientSchema,
  UpdateRecipientSchema,
  RemoveFromCartSchema,
  ViewXsellResultsSchema,
  AddDiscountSchema,
  AddPaymentInfoSchema,
  DiscountErrorSchema,
  DiscountFailureSchema,
  RecipientErrorSchema,
  RemoveDiscountSchema,
  ManualValidationCatchAllSchema,
])

export type EventObject = z.infer<typeof EventObjectSchema>
