import { z } from 'zod'
import {
  basketDataSchema,
  discountDataSchema,
  ecommerceSchema,
  eventDataSchema,
} from './common.schema'

export const BeginCheckoutSchema = z.object({
  event: z.literal('begin_checkout'),

  basket_data: basketDataSchema,
  ecommerce: ecommerceSchema,
  event_data: eventDataSchema,
  discount_data: discountDataSchema,
})

export type BeginCheckoutEvent = z.infer<typeof BeginCheckoutSchema>
