/* istanbul ignore file */
import { gql } from '@moonpig/web-core-graphql'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import {
  getDepartmentTitle,
  getParentDepartment,
} from '@moonpig/web-core-utils'
import { GetSearchSuggestionsQuery } from './__generated__/Suggestions'

type GetSearchSuggestions_searchSuggest_suggestions = NonNullable<
  GetSearchSuggestionsQuery['searchSuggest']['suggestions'][0]
>

export const SUGGESTIONS_LIMIT = 30

export type SearchSuggestion = {
  value: string
  department: {
    enum: DepartmentsEnum
    name: string
    title: string
  }
  isMainSuggestion: boolean
  resultsCount?: number
  score: number
  searchKey?: string
  path?: string
  weightedCount?: number
}

export const GetSearchSuggestionsGQL = gql`
  query GetSearchSuggestions(
    $searchTerm: String!
    $limit: Int = 10
    $suggesterType: SuggesterType = DYNAMIC
    $loadCounts: Boolean! = true
    $experimentValues: String
  ) {
    searchSuggest(
      searchTerm: $searchTerm
      limit: $limit
      suggesterType: $suggesterType
      experimentValues: $experimentValues
    ) {
      suggestions {
        searchTerm
        score
        departmentCounts {
          department
          count @include(if: $loadCounts)
        }
        arguments {
          searchTerm
          filters {
            facets {
              name
              value
              facetKey
              label
              groups {
                group
              }
            }
          }
        }
      }
    }
  }
`

const getSuggestionDepartmentTitle = ({
  department,
  isMainSuggestion,
}: {
  department: string
  isMainSuggestion: boolean
}) => {
  const departmentTitle = getDepartmentTitle(
    department.toUpperCase() as DepartmentsEnum,
  )

  const capitaliseTitle = (title: string) => {
    return title.charAt(0).toUpperCase() + title.slice(1)
  }

  const unPluraliseTitle = (title: string) => {
    return title.slice(0, -1)
  }

  return isMainSuggestion
    ? capitaliseTitle(departmentTitle)
    : unPluraliseTitle(departmentTitle)
}

const sortSuggestionsByCountScoreAndName = (
  a: SearchSuggestion,
  b: SearchSuggestion,
): number => {
  const countSort = (b.resultsCount || 0) - (a.resultsCount || 0)
  return (
    countSort ||
    b.score - a.score ||
    a.value.localeCompare(b.value) ||
    a.department.title.localeCompare(b.department.title)
  )
}

const sortByCountThenSearchTerm = (
  a: {
    suggestion: GetSearchSuggestions_searchSuggest_suggestions
    totalCount: number
  },
  b: {
    suggestion: GetSearchSuggestions_searchSuggest_suggestions
    totalCount: number
  },
) =>
  b.totalCount - a.totalCount ||
  a.suggestion.searchTerm.localeCompare(b.suggestion.searchTerm)

const getSuggestionTotalCount = (
  suggestion: GetSearchSuggestions_searchSuggest_suggestions,
  departmentRestriction?: DepartmentsEnum,
): { weightedCount: number; absoluteCount: number } => {
  const departmentRelativeCounts = {
    ALL_CARDS: 10000,
    ALL_GIFTS: 1000,
    ALL_FLOWERS_AND_PLANTS: 100,
  }
  let deptCounts = suggestion.departmentCounts
  deptCounts = deptCounts.filter(
    x => x.department === (departmentRestriction || x.department),
  )
  let absoluteCount = 0
  const weightedCount = deptCounts.reduce((agr, currentValue) => {
    const parentDepartment:
      | 'ALL_CARDS'
      | 'ALL_GIFTS'
      | 'ALL_FLOWERS_AND_PLANTS' = getParentDepartment(currentValue.department)
    absoluteCount += (currentValue && currentValue.count) || 0
    return (
      agr +
      ((currentValue && currentValue.count) || 0) /
        departmentRelativeCounts[parentDepartment]
    )
  }, 0)
  return { weightedCount, absoluteCount }
}

const mapSingleDepartmentSuggestion = (suggestionDetails: {
  suggestion: GetSearchSuggestions_searchSuggest_suggestions
  department: DepartmentsEnum
  totalCount?: number
  weightedCount?: number
  isMainSuggestion?: boolean
  renderTitle?: boolean
}) => {
  const {
    suggestion,
    totalCount,
    department,
    isMainSuggestion,
    renderTitle,
    weightedCount,
  } = suggestionDetails

  return {
    value: suggestion.searchTerm,
    department: {
      enum: department,
      name: department.toLocaleLowerCase(),
      title: renderTitle
        ? getSuggestionDepartmentTitle({
            department,
            isMainSuggestion: !!isMainSuggestion,
          })
        : '',
    },
    isMainSuggestion: !!isMainSuggestion,
    score: suggestion.score,
    resultsCount: totalCount || undefined,
    weightedCount,
    searchKey: suggestion.arguments.filters?.facets
      ?.map(
        facet =>
          `${facet.groups[0].group.toLowerCase()}:${facet.facetKey}:${
            facet.label
          }`,
      )
      .join('.'),
  }
}

export const departmentalTransformation = (
  suggestions: GetSearchSuggestions_searchSuggest_suggestions[],
  parentDepartment: DepartmentsEnum,
) => {
  const departmentalSuggestions: SearchSuggestion[] = []

  suggestions
    .map(suggestion => {
      const { weightedCount, absoluteCount } = getSuggestionTotalCount(
        suggestion,
        parentDepartment,
      )
      return {
        suggestion,
        totalCount: absoluteCount,
        weightedCount,
      }
    })
    .filter(x => x.totalCount > 0)
    .sort(sortByCountThenSearchTerm)
    .forEach(suggestionWithCount => {
      departmentalSuggestions.push(
        mapSingleDepartmentSuggestion({
          ...suggestionWithCount,
          department: parentDepartment || ('ALL_CARDS' as DepartmentsEnum),
        }),
      )
    })

  const sortedMainSuggestions = departmentalSuggestions
    .filter(x => x.isMainSuggestion)
    .sort((a, b) => sortSuggestionsByCountScoreAndName(a, b))

  const sortedNonMainSuggestions = departmentalSuggestions
    .filter(x => !x.isMainSuggestion)
    .sort((a, b) => sortSuggestionsByCountScoreAndName(a, b))

  return sortedMainSuggestions.concat(sortedNonMainSuggestions)
}
