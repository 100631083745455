import React from 'react'
import { GlobalHead } from '@moonpig/web-core-brand/components'
import { getBrowserCookies, setBrowserCookie } from '@moonpig/web-core-cookies'
import { GraphQLProvider } from '@moonpig/web-core-graphql'
import {
  BrowserErrorTracker,
  Metrics,
  createMetrics,
} from '@moonpig/web-core-monitoring'
import { isServer } from '@moonpig/web-core-utils'
import { AppContext as NextAppContext } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import uuid from 'uuid/v4'
import { createAuthenticatedFetch } from '@moonpig/web-core-fetch'
import { AppContainer } from './AppContainer'
import { trackABTestingBrowser } from './abTesting'
import { getInitialProps } from './getInitialProps'
import { getGraphQLContext } from './graphQLContext'
import { AppCoreProps, AppInitialProps } from './types'
import { setRouteData } from './routeData'

NProgress.configure({
  minimum: 0.35,
  trickleSpeed: 120,
  template: '<div class="bar" role="bar"></div>',
})

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})
Router.events.on('routeChangeError', () => NProgress.done())

class AppCore extends React.Component<React.PropsWithChildren<AppCoreProps>> {
  public static async getInitialProps(
    props: Pick<NextAppContext, 'Component' | 'ctx'> & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: { [key: string]: any }
      environment: { localHostName: string }
      browserErrorTracker: BrowserErrorTracker
      getCookie: (name: string) => string | undefined
      userAgent: string
      metrics: Metrics
      notifications: React.ReactNode[]
      flagSchema: { [id: string]: { default: boolean } }
    },
  ): Promise<AppInitialProps> {
    return getInitialProps(props)
  }

  public componentDidMount() {
    const { mnpg_ui_events_api_correlation_id: correlationId } =
      getBrowserCookies()
    setBrowserCookie(
      'mnpg_ui_events_api_correlation_id',
      correlationId || uuid(),
      {
        maxAge: 1200, // we want 20 min sliding expiration
        path: '/',
      },
    )
  }

  // eslint-disable-next-line react/sort-comp
  public render() {
    const {
      abTestingProps,
      appShellProps,
      browserErrorTracker,
      children,
      enableServerAuth,
      enableThirdPartyScripts,
      experiments,
      flags,
      graphQLInitialState,
      graphQLOptions,
      isMobile,
      notifications,
      pageRequiresLogin,
      pageTracking,
      preview,
      region,
      sessionId,
      loggedIn,
      routeDataToPersist,
      taggstarExperience,
    } = this.props

    if (!isServer) {
      if (routeDataToPersist) {
        setRouteData(routeDataToPersist)
      }

      const { SSID, SSRT, SSSC } = getBrowserCookies()
      trackABTestingBrowser(abTestingProps, { SSID, SSRT, SSSC })

      browserErrorTracker.addMetadata('sessionId', sessionId)
      browserErrorTracker.addMetadata('shortSessionId', sessionId.slice(-8))
    }

    const metrics = createMetrics()

    const authFetch = createAuthenticatedFetch(
      enableServerAuth,
      sessionId,
      metrics,
    )

    const graphQLContext = getGraphQLContext(
      graphQLOptions,
      authFetch.fetch,
      graphQLInitialState,
    )

    return (
      <>
        <GlobalHead />
        <GraphQLProvider context={graphQLContext}>
          <AppContainer
            appShellProps={appShellProps}
            authBaseUrl={graphQLOptions.authBaseUrl}
            browserErrorTracker={browserErrorTracker}
            enableThirdPartyScripts={enableThirdPartyScripts}
            experiments={experiments}
            fetch={authFetch.fetch}
            flags={flags}
            isMobile={isMobile}
            notifications={notifications}
            pageRequiresLogin={pageRequiresLogin}
            pageTracking={pageTracking}
            region={region}
            sessionId={sessionId}
            loggedIn={loggedIn}
            taggstarExperience={taggstarExperience}
          >
            {children}
          </AppContainer>
        </GraphQLProvider>
        <Head>
          {preview && <meta name="robots" content="noindex, nofollow" />}
        </Head>
      </>
    )
  }
}

export { AppCore }
