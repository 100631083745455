export type GACustomerData = {
  id?: string
  isLoggedIn: string
  ctId: string
  lifetimeOrderCount: number
}

const getUserData = () => {
  try {
    const gaUserDataString = localStorage?.getItem('mnpg_ga_user_data')
    return JSON.parse(gaUserDataString || '{}')
  } catch (e) {
    return {}
  }
}

export const getGACustomerData = (cookies: {
  [k: string]: string | undefined
}): GACustomerData => {
  const { id, totalCount } = getUserData()

  return {
    id: cookies.MoonpigUserId,
    ctId: id,
    lifetimeOrderCount: totalCount,
    isLoggedIn: cookies.mnpg_is_authenticated || 'false',
  }
}
