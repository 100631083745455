import React, { FC, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { TertiaryButton } from '@moonpig/launchpad-components'
import {
  IconSystemChevronRight,
  IconSystemChevronLeft,
} from '@moonpig/launchpad-assets'
import { CLOSE_DROPDOWN, useMegaNavContext } from './MegaNavContext'
import { COLOR_KEYLINE, KEYLINE_SIZE_PX } from '../constants'
import { Link } from '../Link'
import { useLocaleText } from '../locale'
import { split } from './utils'

export type NavItemLink = {
  __typename: 'LINK'
  title: string
  url: string
}

export type NavItemNode = {
  __typename: 'NODE'
  title: string
  items: (NavItemLink | NavItemNode)[]
}

type Props = {
  item: NavItemNode
}

const StyledTertiaryButton = styled(TertiaryButton)`
  text-decoration: none;
  border: none;
  ${s({
    color: 'colorInteractionIcon',
    px: 6,
    py: 5,
  })}

  & > span {
    width: 100%;
    display: flex;
    gap: unset;
    justify-content: space-between;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    ${s({
      color: 'colorInteractionIconHover',
    })}
  }
`

const StyledContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${s({ bgcolor: 'colorBackground01' })}
`

const List = styled.ul`
  & > li {
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  ${s({
    py: 5,
    px: 6,
  })}

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export const DropdownVariant: FC<Props> = ({ item }) => {
  const localise = useLocaleText()
  const [, dispatch] = useMegaNavContext()
  const [selectedItem, setSelectedItem] = useState<NavItemNode>(item)

  const handleCloseClick = () => {
    return selectedItem === item
      ? dispatch([CLOSE_DROPDOWN])
      : setSelectedItem(item)
  }

  const [nodes, links] = split(
    selectedItem.items,
    ({ __typename }) => __typename === 'NODE',
  ) as [NavItemNode[], NavItemLink[]]

  return (
    <StyledContainer role="menu">
      <List>
        <li>
          <StyledTertiaryButton
            onClick={handleCloseClick}
            leadingIcon={<IconSystemChevronLeft />}
            width={'100%'}
            aria-label={localise('mega_nav.close_item', selectedItem.title)}
          >
            {selectedItem.title}
          </StyledTertiaryButton>
        </li>
        {nodes.map(subItem => (
          <li key={selectedItem.title + subItem.title}>
            <StyledTertiaryButton
              trailingIcon={<IconSystemChevronRight />}
              width={'100%'}
              onClick={() => setSelectedItem(subItem)}
            >
              {subItem.title}
            </StyledTertiaryButton>
          </li>
        ))}
        {links.map(subItem => (
          <li key={selectedItem.title + subItem.title}>
            <StyledLink href={subItem.url}>{subItem.title}</StyledLink>
          </li>
        ))}
      </List>
    </StyledContainer>
  )
}
