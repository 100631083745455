import { z } from 'zod'

export const OutstandingEventNames = z.enum([
  'abtest',
  'impression',
  'search',
  'view_gallery_results',
  'personalise',
  'create_reminder',
  'delete_reminder',
  'edit_reminder',
  'update_reminder',
  'editor_preview',
  'add_to_wishlist',
  'remove_from_wishlist',
  'editor_exit',
  'search_suggestion',
  'search_suggestion_click',
  'search_input_clear',
  'login_error',
  'login_failure',
  'logout_error',
  'logout_failure',
  'sign_up_error',
  'sign_up_failure',
  'media_upload_failure',
  'media_upload_success',
  'editor_design_update',
  'campaign_details',
  'marketing_consent',
  'share',
  'social_authentication_exception',
  'generic_event',
  'cookie_consent',
  'account_delink_error',
  'account_delink_failure',
  'account_delink_success',
  'account_link_error',
  'account_link_failure',
  'account_link_success',
  'media_complete',
  'media_pause',
  'media_progress',
  'media_start',
  // 'error',
])

/* istanbul ignore next */
export const EventNamesWithSchemas = z.enum([
  // Checkout events
  'purchase',
  'begin_checkout',
  'view_cart',
  'remove_from_cart',
  'add_recipient',
  'delete_recipient',
  'select_recipient',
  'update_recipient',
  'recipient_error',
  'view_xsell_results',
  'add_discount',
  'discount_error',
  'discount_failure',
  'remove_discount',
  'add_payment_info',
  // Customer events
  'customer',
  'login',
  'sign_up',
  'logout',
  // Common events
  'screen_view',
  'add_to_cart',
  'view_item_list',
  'view_item',
  'select_item',
  'select_content',
])

const combinedNames = z.enum([
  ...EventNamesWithSchemas.options,
  ...OutstandingEventNames.options,
])

export type EventNamesEnum = z.infer<typeof combinedNames>
