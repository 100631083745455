import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import { Region } from '@moonpig/web-core-types'
import { getSearchContext } from '@moonpig/web-core-utils'
import { DefaultLayout } from '../components/DefaultLayout'
import { HideSearchBarOption } from '../components/DefaultLayout/types'
import { NavQuery, NavQueryVariables } from './__generated__/layout'
import { NavItem } from '../components/DefaultLayout/data/trackedNavItems'

type LayoutType = 'none' | 'default' | 'checkout'

type LayoutProps = React.ComponentProps<typeof DefaultLayout>

type FooterProps = LayoutProps['footer']

export type LayoutInitialProps =
  | {
      type: 'default'
      navItems: NavItem[]
      footerItems: FooterProps
      shouldHideFooter?: boolean
      hideSearchBar?: HideSearchBarOption
      hideGoogleOneTap?: boolean
      region: Region
      searchDepartment?: {
        name: string
        title: string
      }
      searchTerm?: string
      headerLogoIconUrl?: string
    }
  | {
      type: 'checkout'
      region: Region
      headerLogoIconUrl?: string
    }
  | {
      type: 'none'
    }

export const navQuery = gql`
  query NavQuery($navigationName: String!, $preview: Boolean) {
    navigation(name: $navigationName, preview: $preview) {
      __typename
      items {
        __typename
        title
        icon
        url
        items {
          __typename
          title
          url
          items {
            __typename
            title
            url
          }
        }
      }
    }
    footer: navigation(name: "web-footer", preview: $preview) {
      __typename
      items {
        __typename
        title
        items {
          __typename
          title
          linkHref: url
        }
      }
    }
    content {
      __typename
      headerLogo {
        __typename
        icon {
          __typename
          url(width: 100, height: 100, format: png)
          description
          dimensions {
            __typename
            width
            height
          }
        }
      }
    }
  }
`

const getHeaderLogoIconUrl = (data: NavQuery) => {
  const { headerLogo } = data.content
  if (!headerLogo) return undefined

  const { icon } = headerLogo
  if (!icon) return undefined

  return icon.url
}

type GetLayoutInitialProps = (context: {
  graphQL: { query: GraphQLContext['query'] }
  region: Region
  path: string
  preview: boolean
  query: { [key: string]: string | string[] | undefined }
  layoutType: LayoutType
  shouldHideFooter?: boolean
  hideSearchBar?: HideSearchBarOption
  hideGoogleOneTap?: boolean
  experiments: { [key: string]: string }
}) => Promise<LayoutInitialProps>

export const getLayoutInitialProps: GetLayoutInitialProps = async ({
  graphQL,
  path,
  query,
  region,
  preview,
  layoutType,
  shouldHideFooter,
  hideSearchBar,
  hideGoogleOneTap,
  experiments,
}) => {
  if (layoutType === 'none') {
    return {
      type: 'none',
    }
  }
  const navigationName =
    experiments['explore-nav-redesign']?.toLowerCase() === 'enabled'
      ? 'web-navigation-redesign'
      : 'web-navigation'

  const { data } = await graphQL.query<NavQuery, NavQueryVariables>({
    query: navQuery,
    variables: {
      preview,
      navigationName,
    },
  })

  const headerLogoIconUrl = getHeaderLogoIconUrl(data)

  const searchContext = getSearchContext(path, query)

  if (layoutType === 'checkout') {
    return {
      type: 'checkout',
      region,
      headerLogoIconUrl,
    }
  }

  return {
    type: 'default',
    navItems: data.navigation.items,
    footerItems: data.footer.items,
    shouldHideFooter,
    hideSearchBar,
    hideGoogleOneTap,
    region,
    searchDepartment: searchContext.department,
    searchTerm: searchContext.searchTerm,
    headerLogoIconUrl,
  }
}
