import { Search, SearchVariant } from '@moonpig/web-core-nav'
import React, { useCallback, useEffect, useState } from 'react'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useExperiments } from '@moonpig/web-core-experiments'
import type {
  ReminderItemType,
  SubmitItemType,
  SuggestionItemType,
  UrlSuggestionItemType,
} from '@moonpig/web-core-nav'
import { MAX_SUGGESTIONS } from '@moonpig/web-core-nav/src/Search/constants'
import { FlatNavigationalSuggestion } from './navigationalSuggestions'
import { SearchSuggestion } from './suggestions/Suggestions'
import {
  createSearchGA4Event,
  createSearchGAEvent,
  triggerGAEvent,
} from './analytics'

const departmentToCategory = (department?: string) => {
  switch (department?.toLowerCase()) {
    case 'all_gifts':
      return 'gifts'
    case 'all_flowers_and_plants':
      return 'flowers'
    default:
      return 'cards'
  }
}

const trackClearSearchEvent = () => {
  trackGAEvent({
    event: 'search_input_clear',

    event_data: {
      category: 'search',
      action: 'search input clear',
      label: 'search bar',
      non_interaction: true,
      value: undefined,
    },

    content_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

const trackSuggestionViewEvent = ({
  searchValue,
  count,
  department,
}: {
  department?: string
  searchValue: string
  count: number
}) => {
  trackGAEvent({
    event: 'search_suggestion',

    event_data: {
      category: 'search',
      action: 'suggestions displayed',
      label: 'input',
      non_interaction: true,
      value: count,
    },

    results_data: {
      index: undefined,
      number_of_results: count,
      product_category: departmentToCategory(department),
      results_list: searchValue.toLowerCase(),
      suggestion_type: undefined,
    },

    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
    filter_data: undefined,
  })
}

const trackSuggestionClickEvent = ({
  suggestion,
  position,
  count,
  department,
  type,
}: {
  suggestion: string
  department?: string
  position: number
  count: number
  type: 'navigational' | 'search'
}) => {
  const category = departmentToCategory(department)
  trackGAEvent({
    event: 'search_suggestion_click',

    event_data: {
      category: 'search',
      action: 'suggestion clicked',
      label: `${suggestion} | ${position}/${count} | ${category}`.toLowerCase(),
      non_interaction: true,
      value: undefined,
    },

    results_data: {
      index: position,
      number_of_results: count,
      product_category: category,
      results_list: suggestion.toLowerCase(),
      suggestion_type: type,
    },

    // Web only //
    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
    filter_data: undefined,
  })
}

type TrackedSearchProps = Overwrite<
  React.ComponentProps<typeof Search>,
  {
    navigationalSuggestions?: FlatNavigationalSuggestion[]
    items: SearchSuggestion[]
  }
> & {
  searchValue: string
  searchDepartment?: {
    name: string
  }
}

export const TrackedSearch: React.FC<TrackedSearchProps> = ({
  searchDepartment,
  navigationalSuggestions,
  items,
  onSubmit,
  onKeyUp,
  onFocus,
  onClear = () => {},
  searchValue,
  reminders,
  ...props
}) => {
  const [hadFocus, setHadFocus] = useState(false)
  const [hadInteraction, setHadInteraction] = useState(false)
  const [suggestionsShowEventSent, setSuggestionsShowEventSent] =
    useState(false)
  const matchSearchQueriesToFacetsEnabled =
    useExperiments('search-improved-nba') === 'Enabled'
  const departmentSuggestionsEnabled =
    useExperiments('search-use-department-suggestions')?.toLowerCase() ===
    'enabled'
  const { trackGAEventOnce } = useTrackGAEventOnce()

  useEffect(() => {
    if (searchValue.length > 2 && items.length && !suggestionsShowEventSent) {
      trackSuggestionViewEvent({
        searchValue,
        count: Math.min(items.length, MAX_SUGGESTIONS),
        department: searchDepartment?.name,
      })
      setSuggestionsShowEventSent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, items, searchDepartment?.name])

  useEffect(() => {
    if (
      navigationalSuggestions &&
      navigationalSuggestions?.length > 0 &&
      !suggestionsShowEventSent
    ) {
      trackSuggestionViewEvent({
        department: searchDepartment?.name,
        count: 1,
        searchValue,
      })
      setSuggestionsShowEventSent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationalSuggestions, searchDepartment?.name, searchValue])

  const trackOnFocus = useCallback(
    (value: string) => {
      if (!hadFocus) {
        setHadFocus(true)
        triggerGAEvent({
          event: 'ga_searchFocus',
        })
      }
      onFocus(value)
    },
    [hadFocus, onFocus],
  )

  const trackSubmit = useCallback(
    (submitItem: SubmitItemType) => {
      const { value, itemType } = submitItem

      // if it is a reminder
      if (itemType === 'reminder' && reminders) {
        const reminder = submitItem as ReminderItemType

        const reminderIndex = reminders.findIndex(x => x.id === reminder.id) + 1
        trackGAEvent(
          createSearchGA4Event({
            event: 'search_suggestion_click',
            eventAction: 'suggestion clicked',
            eventLabel:
              `${value} | ${reminderIndex} / ${reminders.length} | reminders`.toLowerCase(),
            index: `${reminderIndex}`,
            inputType: 'no input',
            numberOfResults: reminders.length,
            searchTerm: value,
            subcategories: 'inspiration reminder',
          }),
        )
      }

      if (itemType === 'suggestion') {
        const suggestion = submitItem as SuggestionItemType

        trackSuggestionClickEvent({
          suggestion: suggestion.value,
          position:
            items.findIndex(x => x.searchKey === suggestion.searchKey) + 1,
          count: Math.min(items.length, MAX_SUGGESTIONS),
          department: suggestion.department?.name,
          type: 'search',
        })
      }

      if (itemType === 'navigational' && navigationalSuggestions) {
        const suggestion = submitItem as UrlSuggestionItemType
        trackSuggestionClickEvent({
          suggestion: suggestion.value,
          position:
            navigationalSuggestions.findIndex(x => x.url === suggestion.url) +
            1,
          count: navigationalSuggestions.length,
          type: 'navigational',
        })
      }

      let eventAction = 'keyword'
      if (itemType === 'suggestion') {
        eventAction = 'autocomplete selection'
      }

      if (matchSearchQueriesToFacetsEnabled && itemType === 'search') {
        const matchedSuggestion = items.some(
          s => s.value.toLowerCase() === searchValue.toLowerCase(),
        )
        eventAction = matchedSuggestion ? 'keyword | facet applied' : 'keyword'
      }

      trackGAEvent(
        createSearchGAEvent({
          eventAction,
          eventLabel: value,
        }),
      )

      onSubmit(submitItem)
    },
    [
      items,
      matchSearchQueriesToFacetsEnabled,
      navigationalSuggestions,
      onSubmit,
      reminders,
      searchValue,
    ],
  )

  const trackKeyUp = useCallback(
    (value: string) => {
      if (!hadInteraction && value.length === 1) {
        setHadInteraction(true)
        triggerGAEvent({
          event: 'ga_searchInput',
        })
      }

      onKeyUp(value)
    },
    [hadInteraction, onKeyUp],
  )

  const trackOnClear = useCallback(() => {
    trackClearSearchEvent()
    onClear()
    setSuggestionsShowEventSent(false)
  }, [onClear])

  const trackRemindersVisible = useCallback(() => {
    if (reminders) {
      trackGAEventOnce(
        createSearchGA4Event({
          event: 'search_suggestion',
          eventAction: 'suggestions displayed',
          eventLabel: 'no input',
          eventValue: `${reminders.length}`,
          subcategories: 'inspiration reminder',
          numberOfResults: reminders.length,
          inputType: 'no input',
        }),
      )
    }
  }, [reminders, trackGAEventOnce])

  return departmentSuggestionsEnabled ? (
    <SearchVariant
      {...props}
      reminders={reminders}
      items={items}
      onSubmit={trackSubmit}
      onKeyUp={trackKeyUp}
      onFocus={trackOnFocus}
      onClear={trackOnClear}
      onRemindersVisible={trackRemindersVisible}
      navigationalSuggestions={navigationalSuggestions}
    />
  ) : (
    <Search
      {...props}
      reminders={reminders}
      items={items}
      onSubmit={trackSubmit}
      onKeyUp={trackKeyUp}
      onFocus={trackOnFocus}
      onClear={trackOnClear}
      onRemindersVisible={trackRemindersVisible}
      navigationalSuggestions={navigationalSuggestions}
    />
  )
}
