import { z } from 'zod'
import {
  userDataSchema,
  zodAddressCountString,
  eventDataSchema,
} from './common.schema'

export const SelectRecipientSchema = z.object({
  event: z.literal('select_recipient'),

  user_data: userDataSchema.extend({
    address_count: zodAddressCountString,
  }),

  event_data: eventDataSchema,
})

export type SelectRecipientEvent = z.infer<typeof SelectRecipientSchema>
