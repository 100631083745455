import { z } from 'zod'
import {
  basketDataSchema,
  productDataSchema,
  screenDataSchema,
  userDataSchema,
  zodLowerCaseString,
} from './common.schema'

export const ScreenViewSchema = z.object({
  event: z.literal('screen_view'),
  screen_type: zodLowerCaseString,
  screen_name: zodLowerCaseString,

  basket_data: basketDataSchema.optional(),
  user_data: userDataSchema.optional(),
  screen_data: screenDataSchema.optional(),
  product_data: productDataSchema.optional(),
})

export type ScreenViewEvent = z.infer<typeof ScreenViewSchema>
